import React from "react";

import { OurTradesSidebar } from "../CommonComponents/OurTradesSidebar";
import { Banner } from "../CommonComponents/Banner/Banner";
import { EffincyAccuracyDelivery } from "../CommonComponents/EffincyAccuracyDelivery";
export const RoofingEstimations = () => {
  return (
    <div style={{ backgroundColor: " #CFF0C5" }}>
      <div className=" container-lg">
        <h1 className="ServicesCommonHeadings  text-center">
          Roofing Estimating Services
        </h1>
        <div className=" row w-100 m-sm-0">
          <div className="col-lg-3 col-12 hideonMediumScreen">
            <OurTradesSidebar />
          </div>
          <div className="col-lg-9 col-12 ">
            <p className=" paragraph">
              Predict your future commercial roofing proposal swiftly and
              affordably with the assistance of{" "}
              <strong>PlanHub Estimators</strong> comprehensive takeoff
              solutions. Prior to your building project's inception, it's
              crucial to secure a dependable roofing strategy for a smooth
              process. Our skilled roofing estimator will ensure the provision
              of rapid and precise roofing estimates. we provide 24/7 support
              via chat and email ({" "}
              <a
                style={{ color: "black" }}
                href="mailto:AM@planhubestimating.com"
              >
                AM@planhubestimating.com
              </a>{" "}
              ) or Feel Free to Reach us Via Call{" "}
              <a style={{ color: "black" }} href="tel:+1 917 300 1079">
                {" "}
                +1 917 300 1079
              </a>
            </p>
            <h2 className="ServicesSubCommonHeadings mt-2">
              Our Roofing Estimates
            </h2>

            <p className=" paragraph">
              Our expert Roofing estimators generally work with their expertise
              quickly. They deal with different types of roofing works. Few of
              them are as follows:
              <ul>
                <li>Green Roofs and Green Walls</li>
                <li>Flashing and Sheet Metal</li>
                <li>Membrane Roofing</li>
                <li>Damp Roofing and Waterproofing</li>
                <li>Steep Roofing </li>
                <li> Shingles</li>
                <li>Roof Windows and Skylights </li>
                <li>Common Roofing Materials </li>
              </ul>
              {/* Our usage of Plan swift manages to generate exact material lists
              that enclose the number of yards anyone acquires for a city park
              and several feet edging for any residential area. Plan Swift can
              effectively assist through its different tools to measure areas,
              volumes and lengths to follow complex curves with a certain ease.
              It is a useful count tool to evaluate the exact amount of plants
              to order and their placement. */}
            </p>

            <h2 className="ServicesSubCommonHeadings mt-2">Our Clients:</h2>

            <p className=" paragraph">
              <strong>PlanHub Estimators</strong> has been providing
              professional Roofing Estimating Services for the past decade,
              catering to a diverse range of clients. Few of them are as
              follows:
              <ul>
                <li> General Contractors </li>
                <li> Roofing Contractors </li>
                <li> Membrane Roofing Contractors</li>
                <li>Shingles Contractors </li>
                <li> Steep Contractors</li>
                <li> Damp-proofing and Waterproofing Contractors </li>
                <li> Roof Windows and Skylights contractors </li>
                <li>Roofing Material contractors </li>
              </ul>
            </p>

            <h2 className="ServicesSubCommonHeadings mt-2">
              Residential Roofing Estimating services
            </h2>
            <p className=" paragraph">
              <ul>
                <li> Homes </li>
                <li> Apartments </li>
                <li> Mansions </li>
                <li> Home additions </li>
                <li> Condominium </li>
                <li> Home Improvements </li>
                <li> Mixed used facilities </li>
                <li> Studio Flats </li>
                <li> Custom Homes </li>
              </ul>
            </p>

            <h2 className="ServicesSubCommonHeadings mt-2">
              Commercial Roofing Estimating services
            </h2>
            <p className=" paragraph">
              <ul>
                <li> Offices </li>
                <li> Hospitals </li>
                <li> Airports </li>
                <li>Hotels </li>
                <li> Arenas </li>
                <li> Schools</li>
                <li> Warehouses </li>
                <li> Shopping Malls. </li>
                <li> Restaurants </li>
                <li> Exhibition Buildings </li>
                <li> Fire Stations.</li>
              </ul>
            </p>

            <h2 className="ServicesSubCommonHeadings mt-2">
              Industrial Roofing Estimating services
            </h2>
            <p className=" paragraph">
              <ul>
                <li> Petrochemical </li>
                <li> Mining and Metals </li>
                <li> Marine </li>
                <li>Drilling </li>
                <li> Oil & Gas </li>
                <li> Pharmaceutical Plants</li>
                <li> Food Processing </li>
                <li> Hydrocarbon Processing </li>
                <li> Wastewater plants </li>
                <li> Manufacturing </li>
                <li> Pumping Stations</li>
                <li>Polymers </li>
                <li>Fertilizers </li>
              </ul>
              The majority of our customers are waterproofing contractors, who
              greatly depend on the fine details in our material takeoff. This
              helps them create bids for the general contractor and plays a
              significant role in their procurement or buying decisions. Our
              Industrial Roofing Estimating Service also offer important
              information about where and how to lay the required materials
              beneath the grade slab or on the foundation and footings.
              Contractors that provide air vapor barriers and sealants are
              another important client that makes use of our services to draft
              bids and calculate material requirements.
            </p>
            <div className=" mt-5 mb-5">
              <img
                src={require("../../src/Images/roofingExtrapic.png")}
                width={"100%"}
                alt=""
              />
            </div>
            <EffincyAccuracyDelivery></EffincyAccuracyDelivery>
            <h2 className="ServicesSubCommonHeadings mt-2">
              Waterproofing & Damp Proofing Estimating – Material Takeoff
            </h2>
            <p className=" paragraph">
              Waterproofing contractors rely on our detailed waterproofing &
              damp proofing estimating services to create accurate bids for
              general contractors. Our estimates go beyond just totals – they
              provide a precise breakdown of materials needed, including
              quantities and application locations for foundations, footings,
              and below-grade slabs. Whether you're a waterproofing contractor
              working on foundations and slabs, or an air vapor barrier &
              sealant specialist, we'll provide the precise information you need
              to confidently estimate and execute your projects.
            </p>

            <h2 className="ServicesSubCommonHeadings mt-2">
              Fire Proofing & Fire Stopping Estimating – Material Takeoff
            </h2>
            <p className=" paragraph">
              <strong>PlanHub Estimators </strong> provides expert Fire Proofing
              & Fire Stopping Estimating – Material Takeoff services. Our team
              meticulously analyzes your plans, identifying all fireproofing
              requirement. We ensure all fireproofing areas are clearly marked
              with their designated fire resistance rating. Beams, columns,
              walls, and framing assemblies are all identified and detailed.
              Furthermore, thickness and type of insulation are specified for
              each assembly.
            </p>
          </div>
        </div>
      </div>
      <Banner />
    </div>
  );
};
