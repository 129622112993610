import React from "react";
import { ServicesSidebar } from "../CommonComponents/ServicesSidebar";
// import { Link } from "react-router-dom";
import { Banner } from "../CommonComponents/Banner/Banner";
import { EffincyAccuracyDelivery } from "../CommonComponents/EffincyAccuracyDelivery";

export const Scheduling = () => {
  return (
    <div style={{ backgroundColor: " #CFF0C5" }}>
      <div className=" container-xl">
        <h1 className="ServicesCommonHeadings">Cost Scheduling</h1>
        <div>
          <div className=" row m-0 w-100 ">
            <div className="col-lg-3 col-12 hideonMediumScreen  ">
              <ServicesSidebar servicename="Scheduling" />
            </div>
            <div className="col-lg-9 col-12">
              <div>
                <p className="paragraph">
                  A timetable represents the method in which a comprehensive or
                  abstract project blueprint is converted into a chronologized
                  timetable, taking into consideration the duration, importance,
                  external sequence connections, accessible resources, and
                  project deadlines. This process might shape the execution of
                  the overall strategy by acknowledging practical timeframes and
                  resource limitations.
                </p>
                <h2 className="ServicesSubCommonHeadings">Maintaining</h2>
                <p className=" paragraph">
                  In the process of crafting a project timeline, information is
                  collected from various outlets. Guidelines for submissions are
                  derived from the stipulations and supplementary contract
                  documents. Procedures and exchanges involved in installation
                  are determined from the quotation and contractual documents.
                  The assignment of labor force, durations, and monetary-related
                  specifics for the timeline are derived from the quote.
                  Additionally, the management and overseeing team of the
                  project feed into the timeline fabrication procedure.
                </p>

                <h2 className="ServicesSubCommonHeadings">
                  Scheduling Software
                </h2>
                <p className=" paragraph">
                  Our skilled group excels in creating and overseeing timelines
                  via the subsequent construction consultation tools: Microsoft
                  Project, and Primavera P6.
                  {/* <ul>
                    <li className=" ">Microsoft Project</li>

                    <li className=" ">Primavera P6</li>
                  </ul> */}
                </p>

                <h4 className="ULheadingContainer">
                  Work Breakdown Structure (WBS)
                </h4>
                <p className=" paragraph">
                  Prior to initiating and configuring the Primavera timetable,
                  creating a Work Breakdown Structure (WBS) is essential. When
                  we oversee the work breakdown structure, we examine,
                  establish, and supervise diverse WBS databases for our
                  esteemed clients.
                </p>

                <h4 className="ServicesSubCommonHeadings">
                  Primavera Calendars
                </h4>
                <p className=" paragraph">
                  Calendars enable the setting of permissible workdays and
                  working times for a particular day. Calendars can likewise be
                  designated to every resource and task, ensuring uniform time
                  constraints. They come in three kinds: Global Calendar,
                  Project Calendar, and Resource Calendar.
                  {/* <ul className="mt-4">
                    <li>Global Calendar</li>
                    <li>Project Calendar</li>
                    <li>Resource Calendar.</li>
                  </ul> */}
                </p>

                <h4 className="ServicesSubCommonHeadings">Activities</h4>
                <p className=" paragraph">
                  Divided and organized into more manageable work packages for
                  future resource allocation by our scheduler. We boast a
                  skilled team who can supply the below stated services with
                  Activity Codes for the progression of your construction
                  undertaking. Activity specifics Activity usage pattern
                  Activity usage spreadsheet Resource utilization spreadsheet
                  {/* <ul className="mt-4">
                    <li>Activity details</li>
                    <li>Activity usage profile</li>
                    <li>Activity usage spreadsheet</li>
                    <li>Resource usage spreadsheet</li>
                  </ul> */}
                </p>
                <EffincyAccuracyDelivery />
                <h4 className="ServicesSubCommonHeadings">
                  Activity Relationships
                </h4>
                <p className=" paragraph">
                  Tied to the activities, next in line come the relationships,
                  which signify connections between the project activities,
                  generating the order of which the activities will take place.
                  In Primavera P6 relationships are divided into four
                  categories:
                  <ul className="mt-4">
                    <li>Finish to Start (FS),</li>
                    <li>Start to Start (SS),</li>
                    <li>Finish to Finish (FF),</li>
                    <li>Start to Finish (SF).</li>
                  </ul>
                </p>
                <div className=" mt-5 mb-5">
                  <img
                    src={require("../../src/Images/SchedulingExtrapic.png")}
                    width={"100%"}
                    alt=""
                  />
                </div>
                <h4 className="ServicesSubCommonHeadings">Milestones</h4>
                <p className=" paragraph">
                  Milestones succeed actions and possess a set date. They depict
                  a precise and exact instance within the operation of the
                  project. Milestones are a vital component in a timetable since
                  they provide a glimpse of a particular moment, or a
                  significant occurrence in the project's evolution, as it
                  unfolds, and are frequently employed for budget examinations
                  and assessing the status of the project.
                </p>

                <h4 className="ServicesSubCommonHeadings">Risk</h4>
                <p className=" paragraph">
                  We assess hazards and their influence on a full-scale building
                  project with a hazard rating that typically fluctuates from 0
                  to 72, based on the total value of three factors: probability,
                  expenditure, and timeline.
                </p>
                <h4 className="ServicesSubCommonHeadings">
                  Schedule Baselines
                </h4>
                <p className=" paragraph">
                  We assess dangers and their effect on a complete building
                  project together with a danger rating that typically
                  oscillates between 0 - 72, depending on the total value of
                  three factors including likelihood, expense and timeline.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Banner />
    </div>
  );
};
