import React from "react";
import { OurTradesSidebar } from "../CommonComponents/OurTradesSidebar";
import { Banner } from "../CommonComponents/Banner/Banner";
import { EffincyAccuracyDelivery } from "../CommonComponents/EffincyAccuracyDelivery";
export const MetalsEstimation = () => {
  return (
    <div style={{ backgroundColor: " #CFF0C5" }}>
      <div className=" container-lg">
        <h1 className="ServicesCommonHeadings  text-center">
          Metals Estimating Services
        </h1>
        <div className=" row w-100 m-sm-0">
          <div className="col-lg-3 col-12 hideonMediumScreen">
            <OurTradesSidebar />
          </div>
          <div className="col-lg-9 col-12 ">
            <p className=" paragraph">
              <b> PlanHub Estimators</b> is your dependable ally for precise and
              trustworthy metal projections for construction endeavors. We
              recognize the vital importance of metals in establishing
              stability, visual appeal, and practicality in construction. Our
              proficiency and dedication to excellence guarantee that you get
              accurate metal estimates customized to meet your unique project
              needs.
            </p>
            <h2 className="ServicesSubCommonHeadings mt-2">
              Our Metal Estimation Process
            </h2>
            <p className=" paragraph">
              <b>Initial Consultation: </b>
              We begin by discussing your project objectives, design tastes, and
              financial limitations. This allows us to comprehend the extent and
              vision of your construction undertaking.
            </p>
            {/* <p className=" paragraph">
              <b>Site Assessment:</b>
              If needed, we carry out a meticulous site examination to account
              for particular factors influencing metal choice, such as
              environmental conditions or weight-bearing demands.
            </p> */}
            <p className=" paragraph">
              <b>Material Selection:</b>According to your project's
              specifications, we suggest the most appropriate metals and alloys,
              taking into account aspects like strength, corrosion resistance,
              and visual appeal.
            </p>
            <p className=" paragraph">
              <b>In-Depth Estimation: </b> Our team supplies a thorough analysis
              of the projected costs for each metal required, including
              quantities and any supplementary charges like fabrication or
              finishing.
            </p>
            {/* <p className=" paragraph">
              <b>Presentation and Approval: </b> : We submit the estimate to
              you, clarifying each component and addressing any queries or
              concerns you might have. Upon approval, we proceed with
              procurement.
            </p> */}
            {/* <p className=" paragraph">
              <b>Procurement and Quality Assurance:</b> We oversee the
              procurement process, making certain that the metals adhere to the
              stipulated standards. Our team performs quality assessments to
              confirm that the materials align with the project criteria.
            </p> */}

            <div className=" mt-5 mb-5">
              <img
                src={require("../../src/Images/MatelExtrapic.png")}
                width={"100%"}
                alt=""
              />
            </div>
            <h2 className="ServicesSubCommonHeadings mt-2">
              Our steel estimating services include:
            </h2>
            <p className=" paragraph">
              <ul>
                <li>
                  Detailed and accurate steel takeoffs and estimates ranging
                  from budget, the order of magnitude, design, and bid estimate.
                </li>
                <li>Precast estimation</li>
                <li>Cold-form steel estimation</li>
                <li>Steel frames takeoffs</li>
                <li>Rebar estimation</li>
                <li>Structural steel takeoffs</li>
                <li>Bar bending schedules</li>
                <li>Change order management</li>
                <li>Rebar project management</li>
                <li>CPM scheduling</li>
                <li>Conflict Resolution</li>
                <li>Consultancy on smartly filing bids</li>
              </ul>
            </p>
            <EffincyAccuracyDelivery />
            <h2 className="ServicesSubCommonHeadings mt-2">
              rebar & structural steel estimating services
            </h2>
            <p className=" paragraph">
              With the ever-changing construction industry trends, the
              competition becomes intense and intense, only the precise
              estimates can mitigate the chances of overbidding and underbidding
              on your projects. You can count on{" "}
              <strong>PlanHub Estimators </strong>
              for your rebar or reinforcing steel takeoffs as we have
              successfully delivered highly accurate and comprehensive estimates
              to rebar contractors for materials, accessories, and erection
              costs. Our rebar estimators have the capability and software
              expertise to handle the project of any type and complexity
              including bridges, residential buildings, commercial buildings,
              industrial buildings, infrastructures, culverts, retaining walls,
              wing walls, etc. We make use of 3d model technology for steel
              estimating that depicts a clear understanding of the scope of work
              thus helping inaccurate cost analysis and feasibility. Our rebar
              estimating deliverables includes:
              <ul>
                <li>Contract plans & numbers</li>
                <li>Take-off Quantities</li>
                <li>Rebar Weight</li>
                <li>Structural Activity Codes (SAC)</li>
                <li>Part Codes (Rebar, wire mesh and other accessories)</li>
                <li>Steel grade (e.g. ASTM A108)</li>
                <li>Structure wise or scope wise bid item number</li>
                <li>Appendix</li>
                <li>Locations</li>
                <li>Reference Sections</li>
              </ul>
              Need metalwork? Contact us via our email{" "}
              <a
                style={{ color: "black" }}
                href="mailto:AM@planhubestimating.com"
              >
                AM@planhubestimating.com
              </a>
              &nbsp; or Call us at &nbsp;{" "}
              <a style={{ color: "black" }} href="tel:+1 917 300 1079">
                {" "}
                +1 917 300 1079
              </a>
            </p>

            <div className=" mt-5 mb-5">
              <img
                src={require("../../src/Images/MatelExtrapic2.png")}
                width={"100%"}
                alt=""
              />
            </div>
            <h2 className="ServicesSubCommonHeadings mt-2">
              {/* REBAR & STRUCTURAL STEEL DETAILING SERVICES */}
              rebar & structural steel Detailing services
            </h2>
            <p className=" paragraph">
              <strong>PlanhHub Estimators</strong> have rebar drafting experts
              who are proficient in the latest CAD software such as AutoCAD,
              Rebar CAD, ASA Microstation and Tekla, allowing them to create
              comprehensive construction drawings that simplify rebar placement
              to Build complex structures. Our services are sought after by
              structural engineers, rebar fabricators and installers as detailed
              drawings facilitate rebar positioning, thereby speeding up the
              construction process. Trust us with your detailed needs for quick,
              accurate and easy-to-follow drawings. We provide CAD drawings and
              details in ACI, CRSI, ASTM, AS and BS coding formats including:
              <ul>
                <li>Structural BIM modeling</li>
                <li>Rebar Shop Drawings</li>
                <li>Rebar Placement Drawings</li>
                <li>Rebar Detailing</li>
                <li>Structural and Light Steel Detailing</li>
                <li>Steel Joist & Deck Detailing</li>
                <li>Bar Bending Schedules</li>
                <li>Precast Concrete Detailing</li>
                <li>Retaining Walls Detailing</li>
                <li>Foundation Detailing</li>
                <li>Shear Wall Detailing</li>
                <li>Concrete Stair Detailing</li>
                <li>Footings Detailing</li>
                <li>Grade Beam Detailing</li>
                <li>Precast Masonry Stone Detailing</li>
                <li>Connection Sketches</li>
                <li>Drawing Index</li>
                <li>Single part Fitting Drawings</li>
                <li>2D Drafting & 3D Structural Modeling</li>
                <li>
                  Shop/Fabrication Drawings (column, beam, brace, stair,
                  handrail, ladders, etc.)
                </li>
                <li>General Arrangement/ Erection Drawings for Connections</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <Banner />
    </div>
  );
};
