import React from "react";
import { OurTradesSidebar } from "../CommonComponents/OurTradesSidebar";
import { Banner } from "../CommonComponents/Banner/Banner";
import { EffincyAccuracyDelivery } from "../CommonComponents/EffincyAccuracyDelivery";

export const MechanicalEstimatingServices = () => {
  return (
    <div style={{ backgroundColor: " #CFF0C5" }}>
      <div className=" container-lg">
        <h1 className="ServicesCommonHeadings  text-center">
          Mechanical Estimating Services
        </h1>
        <div className=" row w-100 m-sm-0">
          <div className="col-lg-3 col-12 hideonMediumScreen">
            <OurTradesSidebar />
          </div>
          <div className="col-lg-9 col-12 ">
            <p className=" paragraph">
              <b>PlanHub Estimators </b> empowers mechanical contractors to win
              bids and boost profits. Our experienced MEP cost estimators use
              advanced technology to deliver accurate project estimates – from
              small jobs to billion-dollar projects! We customize estimates to
              your specific project needs, whether it's HVAC, plumbing,
              refrigeration, or piping. <b>PlanHub Estimators </b> utilize the
              latest zip code-specific data for material, equipment, and labor
              costs. Furthermore, our team partner with you throughout the
              design phase, providing independent estimates for change orders
              and value engineering. For a free consultation, reach out to us
              right now at
              <a style={{ color: "black" }} href="tel:+1 917 300 1079">
                {" "}
                +1 917 300 1079
              </a>
            </p>

            <h2 className="ServicesSubCommonHeadings mt-2">
              The Range Of Mechanical Estimating Services
            </h2>

            <p className=" paragraph">
              <ul>
                <li>Mechanical, plumbing, piping, HVAC quantity takeoffs</li>
                <li>Budget estimates</li>
                <li>Bid estimates</li>
                <li>Bid reviews</li>
                <li>Check estimates</li>
                <li>Reconciliation estimates</li>
                <li>Change order management</li>
                <li>Project cost management</li>
                <li>Subcontractor marketing</li>
                <li>Mechanical project lead generation</li>
              </ul>
            </p>
            <EffincyAccuracyDelivery />

            <h2 className="ServicesSubCommonHeadings mt-2">
              What Do We Quantify In Mechanical Takeoffs?
            </h2>
            <p className=" paragraph">
              We have a team of specialized mep estimators who have the
              expertise in using the latest mechanical takeoff software like
              Bluebeam, OST (on-screen takeoff), planswift, etc. Following items
              are generally quantified in our mechanical takeoffs:
            </p>
            <div className=" row">
              <div className=" col-md-6 col-12">
                <ul>
                  <li className=" paragraph">Air devices</li>
                  <li className=" paragraph">Air handling units</li>
                  <li className=" paragraph">Air-cooled condensing units</li>
                  <li className=" paragraph">Air conditioning units</li>
                  <li className=" paragraph">Exhaust fans</li>
                  <li className=" paragraph">Manual volume damper</li>
                  <li className=" paragraph">Rooftop units</li>
                  <li className=" paragraph">Dust smoke detector</li>
                  <li className=" paragraph">Pump</li>
                  <li className=" paragraph">Louver</li>
                  <li className=" paragraph">Ducts</li>
                  <li className=" paragraph">Gas pipes</li>
                  <li className=" paragraph">Vent pipes</li>
                  <li className=" paragraph">Refrigerate pipes</li>
                  <li className=" paragraph">Condensate drain pipes</li>
                  <li className=" paragraph">Pipe fittings</li>
                </ul>
              </div>

              <div className=" col-md-6 col-12">
                <ul>
                  <li className=" paragraph">Sheet metal ducts</li>
                  <li className=" paragraph">Duct fittings</li>
                  <li className=" paragraph">Thermostat</li>
                  <li className=" paragraph">Duct insulation</li>
                  <li className=" paragraph">Duct supports</li>
                  <li className=" paragraph">Hangers</li>
                  <li className=" paragraph">Boilers</li>
                  <li className=" paragraph">Heat pumps</li>
                  <li className=" paragraph">Humidifiers</li>
                  <li className=" paragraph">Air filters</li>
                  <li className=" paragraph">Air cleaners</li>
                  <li className=" paragraph">Steam systems</li>
                  <li className=" paragraph">Water heaters</li>
                  <li className=" paragraph">Chillers</li>
                  <li className=" paragraph">Backdraft damper</li>
                </ul>
              </div>
            </div>
            {/* <h2 className="ServicesSubCommonHeadings mt-2">
              Mechanical Piping Estimating
            </h2>
            <p className=" paragraph">
              Being the best MEP estimating team in the market, we offer highly
              precise estimates to mechanical piping contractors involved in
              welding and installation of the piping systems. Our reliable and
              comprehensive estimates help them in bidding, budgeting,
              procurement, and scheduling. With having the practical knowledge
              of serving commercial and industrial piping projects, we have the
              expertise and skill to perform the cost analysis of the piping
              project of any complexity. We have vast exposure in process and
              utility industries such as petrochemical, paper, pharmaceutical,
              wastewater treatment, power generation, biofuels, etc. We have the
              portfolio of providing estimating solutions for the following
              mechanical piping systems:
              <ul>
                <li>HVAC piping</li>
                <li> Power boilers</li>
                <li>Process piping</li>
                <li>Hydraulic piping</li>
                <li>Control piping</li>
                <li>Gas piping</li>
                <li>Utility piping</li>
                <li>Power plant piping</li>
                <li>Steel mill piping</li>
                <li>Prefabricated piping systems</li>
                <li>Pre insulated piping systems</li>{" "}
                <li>Grooved piping systems</li>
              </ul>
            </p> */}
            <div className=" mt-5 mb-5">
              <img
                src={require("../../src/Images/MechanicalExtrapic.png")}
                width={"100%"}
                alt=""
              />
            </div>
            <h2 className="ServicesSubCommonHeadings mt-2">
              Mechanical & Piping Insulation Estimating Services
            </h2>
            <p className=" paragraph">
              For mechanical insulation contractors to become more successful
              and earn healthy profit margins, they need to provide accurate
              estimates. For an exact cost estimate for material and labor, let
              us digitally take off your insulation projects to measure the
              dimensions accurately and account for different forms like round,
              oval, and rectangular pipes or ducts. In order to give you the
              accurate material and labor costs for a wide range of difficult
              mechanical systems, our estimators are skilled with insulation
              estimation tools such as Fastwrap. In order to meet your
              individual needs, we tailor our deliverables and create estimates
              based on your mechanical estimating project. Our estimating
              services for insulation include:
              <ul>
                <li>Pipe insulation</li>
                <li> Ductwork insulation</li>
                <li>Cold storage insulation </li>
                <li>Boiler & turbine </li>

                <li>Acoustic systems </li>
                <li>Fireproofing </li>
                <li>Firestopping </li>

                <li> Weatherproofing</li>
                <li>Moisture barrier </li>
                <li>Tank insulation systems </li>
                <li>Tank insulation systems </li>
                <li> Removable insulation systems</li>
                <li> Equipment insulation systems</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <Banner />
    </div>
  );
};
