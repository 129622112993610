import React from "react";
import { ServicesSidebar } from "../CommonComponents/ServicesSidebar";
import { Banner } from "../CommonComponents/Banner/Banner";

export const Planning = () => {
  return (
    <div style={{ backgroundColor: " #CFF0C5" }}>
      <div className="container-xl">
        <h1 className="ServicesCommonHeadings">Planning</h1>
        <div>
          <div className=" row m-0 w-100 ">
            <div className="col-lg-3 col-12 hideonMediumScreen">
              <ServicesSidebar servicename="PLANNING" />
            </div>
            <div className="col-lg-9 col-12">
              <p className=" paragraph">
                {" "}
                Within the realm of project administration, the initial and
                significant phase is preparation. This process involves the
                creation of an exhaustive layout for the progression of the
                project. It's important to note, planning is not equivalent to
                scheduling. Formulating a plan is akin to creating a
                comprehensive chart with project leads and construction teams,
                whilst scheduling is like transforming that chart into a time
                schedule assisted by scheduling professionals. Therefore,
                planning determines the direction, while scheduling ensures the
                timing aligns perfectly.
              </p>
              <h2 className="ServicesSubCommonHeadings">
                What is Construction Master Plan?
              </h2>
              <p className=" paragraph">
                {" "}
                A blueprint for construction guides how the project should be
                organized, planned, and have its primary work activities
                directed to accomplish the aims of completing the task
                punctually, within monetary limits, and as outlined. This
                roadmap for construction serves as a guiding principle on how
                the project should be systematized, synchronized, and have its
                chief operational activities managed to attain the objectives of
                wrapping up the work within the deadline, staying within
                financial allotments, and following the prescribed
                specifications. A construction blueprint restates how to
                strategize the project, structure it, and oversee its core tasks
                so as to fulfill the targets of finishing the work in a timely
                manner, budget-consciously, and sticking to stipulated
                specifications. The contracting scheme is a key factor in
                shaping the primary construction plan, which offers numerous
                solutions. Queries concerning governmental and societal
                constraints, construction resources, proprietor's rules or
                lawful necessities, contractual stipulation that affect the
                master plan aren't addressed by the contracting scheme. These
                issues need to be resolved during the lifecycle of the project
                execution scheme. The project execution design must be
                scrutinized and judgments made as the work advances. Minor
                discrepancies are typical but substantial amendments should be
                approached with utmost caution.
              </p>
              <div className=" mt-5 mb-5">
                  <img
                    src={require("../../src/Images/planingExtrapic.png")}
                    width={"100%"}
                    alt=""
                  />
                </div>
              <h2 className="ServicesSubCommonHeadings">
                The Significance of Effective Planning
              </h2>
              <div className="mt-3">
                <p className=" ULheadingContainer">
                  1. Clear Objectives and Scope Definition
                </p>
                <ul>
                  <li className=" paragraph">
                    Definition: Planning starts with a thorough understanding of
                    project objectives, scope, and deliverables.
                  </li>
                  <li className=" paragraph">
                    Benefits: Helps in setting realistic goals, aligning
                    expectations, and avoiding scope creep.
                  </li>
                </ul>
                <p className=" ULheadingContainer">
                  2. Resource Allocation and Optimization
                </p>
                <ul>
                  <li className=" paragraph">
                    Definition: Planning identifies the resources required -
                    including manpower, materials, and equipment - and allocates
                    them efficiently.
                  </li>
                  <li className=" paragraph">
                    Benefits: Helps in setting realistic goals, aligning
                    expectations, and avoiding scope creep.
                  </li>
                </ul>
                <p className=" ULheadingContainer">
                  3. Risk Identification and Mitigation
                </p>
                <ul>
                  <li className=" paragraph">
                    Definition: Planning involves identifying potential risks
                    and developing strategies to mitigate or manage them.{" "}
                  </li>
                  <li className=" paragraph">
                    Benefits: Proactively addressing risks minimizes their
                    impact on the project's success.
                  </li>
                </ul>
                <p className=" ULheadingContainer">
                  4. Time Management and Scheduling
                </p>
                <ul>
                  <li className=" paragraph">
                    Definition: A well-structured plan includes a timeline with
                    clearly defined tasks and milestones.
                  </li>
                  <li className=" paragraph">
                    Benefits: Ensures that tasks are completed in a logical
                    sequence, preventing delays and bottlenecks.
                  </li>
                </ul>
              </div>

              <h2 className="ServicesSubCommonHeadings">
                Key Elements of Effective Planning
              </h2>

              <div>
                {" "}
                <p className=" paragraph">
                  {" "}
                  <span className=" ULheadingContainer">
                    1. Define Project Objectives and Deliverables
                  </span>{" "}
                  Clearly articulate what needs to be achieved by the end of the
                  project.
                </p>
                <p className=" paragraph">
                  {" "}
                  <span className=" ULheadingContainer">
                    {" "}
                    2. Scope Definition:
                  </span>{" "}
                  Clearly outline the boundaries and limitations of the project
                  to avoid scope creep.
                </p>
                <p className=" paragraph">
                  {" "}
                  <span className=" ULheadingContainer">
                    3. Risk Assessment and Management:
                  </span>{" "}
                  Identify potential risks and develop contingency plans.
                </p>
                <p className=" paragraph">
                  {" "}
                  <span className=" ULheadingContainer">
                    4. Resource Allocation and Budgeting:
                  </span>{" "}
                  Determine the resources required and allocate them according
                  to priority.
                </p>
                <p className=" paragraph">
                  {" "}
                  <span className=" ULheadingContainer">
                    5. Task Sequencing and Dependencies:
                  </span>{" "}
                  Understand the logical flow of tasks and their dependencies.
                </p>
                <p className=" paragraph">
                  {" "}
                  <span className=" ULheadingContainer">
                    {" "}
                    6. Communication Plan:
                  </span>{" "}
                  Establish a communication framework to ensure all stakeholders
                  are informed and engaged.
                </p>
                <p className=" paragraph">
                  {" "}
                  <span className=" ULheadingContainer">
                    7. Monitoring and Control Measures:
                  </span>{" "}
                  Define how progress will be tracked and what actions will be
                  taken if the project veers off course.
                </p>
              </div>

              <h2 className="ServicesSubCommonHeadings">
                Steps to Create a Robust Project Plan
              </h2>
              <div>
                <p className=" ULheadingContainer">1. Project Initiation </p>
                <ul>
                  <li className=" paragraph">
                    <span className="ULheadingContainer">
                      Define Objectives and Scope:{" "}
                    </span>
                    Clearly articulate what the project aims to achieve and what
                    is included in the project's scope.
                  </li>
                  <li className=" paragraph">
                    <span className="ULheadingContainer">
                      Identify Stakeholders:{" "}
                    </span>
                    Determine who the key stakeholders are and understand their
                    interests and expectations.{" "}
                  </li>
                </ul>

                <p className=" ULheadingContainer">
                  2. Risk Assessment and Mitigation
                </p>
                <ul>
                  <li className=" paragraph">
                    <span className="ULheadingContainer">Identify Risks: </span>
                    Analyze potential risks and uncertainties that could impact
                    the project.
                  </li>
                  <li className=" paragraph">
                    <span className="ULheadingContainer">
                      Develop Risk Response Plans:{" "}
                    </span>
                    Develop strategies to mitigate, transfer, or accept
                    identified risks.
                  </li>
                </ul>

                <p className=" ULheadingContainer">
                  3. Resource Allocation and Scheduling
                </p>
                <ul>
                  <li className=" paragraph">
                    <span className="ULheadingContainer">
                      Allocate Resources:{" "}
                    </span>
                    Determine the human, financial, and material resources
                    required for each task.
                  </li>
                  <li className=" paragraph">
                    <span className="ULheadingContainer">
                      Create a Timeline:{" "}
                    </span>
                    Develop a project schedule, including task durations,
                    dependencies, and milestones.
                  </li>
                </ul>

                <p className=" ULheadingContainer">4. Communication Plan</p>
                <ul>
                  <li className=" paragraph">
                    <span className="ULheadingContainer">
                      Define Communication Channels:{" "}
                    </span>
                    Determine the human, financial, and material resources
                    required for each task.
                  </li>
                  <li className=" paragraph">
                    <span className="ULheadingContainer">
                      Create a Timeline:{" "}
                    </span>
                    Specify how information will be shared among team members
                    and stakeholders.
                  </li>
                </ul>

                <p className=" ULheadingContainer">5. Monitoring and Control</p>
                <ul>
                  <li className=" paragraph">
                    <span className="ULheadingContainer">Track Progress: </span>
                    Regularly monitor project progress against the established
                    plan.
                  </li>
                  <li className=" paragraph">
                    <span className="ULheadingContainer">
                      Implement Corrective Actions:{" "}
                    </span>
                    Take necessary steps to address deviations from the plan.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Banner></Banner>
    </div>
  );
};
