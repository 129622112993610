import React from 'react'

export const NotFound = () => {
  return (
    <div>
        <h1>
        Sorry..!this page is not avaliable
        </h1>
    </div>
  )
}
