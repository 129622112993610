import React from "react";
import { OurTradesSidebar } from "../CommonComponents/OurTradesSidebar";
import { Banner } from "../CommonComponents/Banner/Banner";
import { EffincyAccuracyDelivery } from "../CommonComponents/EffincyAccuracyDelivery";
export const PlumbingEstimation = () => {
  return (
    <div style={{ backgroundColor: " #CFF0C5" }}>
      <div className=" container-lg">
        <h1 className="ServicesCommonHeadings  text-center">
          Plumbing Estimating Services
        </h1>
        <div className=" row w-100 m-sm-0">
          <div className="col-lg-3 col-12 hideonMediumScreen">
            <OurTradesSidebar />
          </div>
          <div className="col-lg-9 col-12 ">
            <p className=" paragraph">
              <strong>PlanHub Estimators </strong>takes the hassle out of
              plumbing estimates for contractors across all over the America.
              For over 10 years, we've helped clients secure winning bids with
              fast, accurate estimates. Our team understands the unique
              challenges of plumbing projects, and utilizes the latest software
              for on-screen takeoffs. We factor in local labor rates and
              material costs for precise pricing. Don't have time to decipher
              complex plans? Our experts will meticulously review them for
              accuracy. From small residential jobs to large commercial
              projects, we handle it all. We offer estimating services for:
              <br />
              • Residential (Private & Public)
              <br />
              • Commercial
              <br />
              • Industrial
              <br />• And More!
            </p>

            <h2 className="ServicesSubCommonHeadings mt-2">
              Range of Plumbing Estimating Services
            </h2>

            <p className=" paragraph">
              <ul>
                <li>Material Takeoffs </li>
                <li>Budget Estimates </li>
                <li>Conceptual Estimates</li>
                <li>Detailed Design Development Estimates</li>
                <li>Bid Estimates </li>
                <li>Bid Reviews </li>
                <li>Bidding Assistance </li>
                <li>Unbiased Third Party Estimate</li>
                <li>Procurement & Change Orders</li>
                <li> Project Cost Management</li>
                <li>Subcontractor Marketing</li>
              </ul>
            </p>

            <h2 className="ServicesSubCommonHeadings mt-2">
              Residential Plumbing Estimating Services
            </h2>
            <p className=" paragraph">
              Whether you are a large or small residential plumbing contractor,
              you need an accurate estimate for your plumbing work in order to
              bid on contracts, provide pricing quotes to clients, and purchase
              materials from a local vendor. With that, we can support you. You
              can establish your profit margins, save time, and meet deadlines
              by working with our MEP estimators to quickly and accurately
              prepare estimates. We take care to include all pertinent
              information, including the exact quantity of insulation, pipes,
              fittings, taps, valves, and other accessories like toilets, sinks,
              showers, and faucets. All types of household plumbing work are
              quantified by us.
              <ul>
                <li>Kitchen & Bathroom Plumbing</li>

                <li>Water Heaters</li>

                <li>Gas Lines</li>

                <li>Main Sewer Lines</li>

                <li>Water Lines</li>

                <li>Floor Drains & Laundries</li>

                <li>Garbage Disposal Ducts</li>

                <li>Domestic HVAC Systems</li>

                <li>Swimming Pool Plumbing</li>
              </ul>
            </p>

            <EffincyAccuracyDelivery></EffincyAccuracyDelivery>

            <h2 className="ServicesSubCommonHeadings mt-2">
              Commercial Plumbing Estimating Services
            </h2>
            <p className=" paragraph">
              Planning a commercial plumbing project? Our experts understand the
              complexities of multi-story buildings and ensure all components –
              pipes, fixtures, valves, and more – are meticulously itemized. We
              leverage location-based pricing for materials, labor, and
              equipment, keeping your bids competitive and your project on
              track.
              <ul>
                <li>Solar Hot Water Heating Systems</li>

                <li>Sewer, Gas & Water Lines</li>

                <li>Commercial HVAC Systems</li>

                <li>Commercial Water Heaters</li>

                <li>Sanitary Plumbing and Drainage</li>

                <li>Sub-Soil Drainage Systems</li>

                <li>Stormwater Discharge</li>

                <li>Water Conservation</li>

                <li>Water Filtration</li>
              </ul>
            </p>
            <h2 className="ServicesSubCommonHeadings mt-2">
              Industrial Plumbing Estimating Services
            </h2>
            <p className=" paragraph">
              In order to provide an accurate cost estimate, our team of
              qualified MEP estimators efficiently measures all the pipe,
              fittings, fixtures, flanges, valves, accessories, and
              equipment—even the most intricate plumbing systems. We have
              completed cost analyses for a broad range of industrial plumbing
              projects in the following industries: waste treatment facilities,
              chemical processing, brewing, power plants, paper mills, food and
              beverage, CO-generation, aerospace manufacturing, and biofuels.
              The following plumbing systems' installation, upkeep, repair, and
              servicing contractors have received estimates from us:
              <ul>
                <li>Industrial Gas Systems</li>

                <li>Steam & Condensate Systems</li>

                <li>Compressed Air Systems</li>

                <li>Hot & Chilled Water Systems</li>

                <li>Potable & Non-potable Water Systems</li>

                <li>Sewer & Wastewater Systems</li>

                <li>Stormwater Discharge</li>

                <li>Process Cooling & Heating Systems</li>

                <li>Water Filtration</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <Banner />
    </div>
  );
};
